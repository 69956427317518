<template>
  <a @click="handleChange" :title="title" type="text" :class="[icon,'sider-trigger-a', collapse ? 'collapsed' : '']"/>
</template>
<script>
export default {
  name: 'SiderTrigger',
  data () {
    return {
      collapse: this.collapsed
    }
  },
  props: {
    // 折叠状态
    collapsed: {
      type: Boolean,
      require: false
    },
    // 图标尺寸
    size: {
      type: Number,
      default: 30
    }
  },
  computed: {
    icon () {
      return this.collapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'
    },
    title () {
      return this.collapse ? '折叠停发电统计' : '展开停发电统计'
    }
  },
  methods: {
    handleChange () {
      this.collapse = !this.collapse
      this.$emit('on-change', this.collapse)
    }
  }
}
</script>
<style>
 .sider-trigger-a {
  width: 20px;
  height: 20px;
  text-align: center;
  color: #5c6b77;
 }
</style>
