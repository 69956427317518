<template>
  <div class="contrainer minWidth1000">
    <div class="contrainer-sider">
      <sider-trigger @on-change="siderChange"/>
    </div>
    <div class="contrainer-left" v-if="showStatistics">
      <custom-actions class="card" title="停发电统计">
        <template #header>
        <div style="text-align:left;width: 300px;">{{statisticsTime}}</div>
        </template>
        <el-table height="calc(100vh - 189px)" stripe size="mini" border v-loading="loading2" :data="statisticsData">
          <el-table-column min-width="80" label="区域" prop="stationName"></el-table-column>
          <el-table-column min-width="80" label="实时停电数量" align="center">
            <el-table-column min-width="60" label="重要" prop="powerFailureNum1"></el-table-column>
            <el-table-column min-width="60" label="标准" prop="powerFailureNum2"></el-table-column>
            <el-table-column min-width="60" label="其他" prop="powerFailureNum3"></el-table-column>
          </el-table-column>
           <el-table-column min-width="80" label="实时发电数量"  align="center">
            <el-table-column min-width="60" label="重要" prop="powerGenerationNum1"></el-table-column>
            <el-table-column min-width="60" label="标准" prop="powerGenerationNum2"></el-table-column>
            <el-table-column min-width="60" label="其他" prop="powerGenerationNUm3"></el-table-column>
          </el-table-column>
          </el-table>
      </custom-actions>
    </div>
    <div class="contrainer-right">
      <custom-actions>
        <template #header>
          <el-button type="primary" @click="toSetting">告警语音配置</el-button>
          <el-button type="primary" @click="exportFile">导出</el-button>
        </template>
        <el-tabs class="tabs" v-model="searchForm.type" @tab-click="onSearch">
          <el-tab-pane label="停发电实时详情" name="real">
            <el-table stripe size="mini" border v-loading="loading" :data="tableData">
          <el-table-column min-width="80" label="省" prop="province"></el-table-column>
          <el-table-column min-width="80" label="市" prop="city"></el-table-column>
          <el-table-column min-width="80" label="县" prop="country"></el-table-column>
          <el-table-column min-width="80" label="网格" prop="region"></el-table-column>
          <el-table-column min-width="100" label="站址名称" prop="stationName"></el-table-column>
          <el-table-column min-width="80" label="站址编号" prop="stationCode"></el-table-column>
          <el-table-column min-width="95" label="直流实时电压" prop="v46"></el-table-column>
          <el-table-column min-width="150" label="停电开始时间" prop="powerFailureTime"></el-table-column>
          <el-table-column min-width="150" label="发电开始时间" prop="powerGenerationTime"></el-table-column>
          <el-table-column min-width="150" label="发电结束时间" prop="powerGenerationEndTime"></el-table-column>
          <el-table-column min-width="70" label="路途时长" prop="duration"></el-table-column>
          </el-table>
          </el-tab-pane>
          <el-tab-pane label="停发电历史数据" name="his">
            <el-form inline label-width="70px" style="padding-bottom:15px;">
          <el-form-item label="站址编码">
            <el-input v-model="searchForm.stationCode" placeholder="站址编码" />
          </el-form-item>
           <el-form-item label="基站名称">
            <el-input v-model="searchForm.stationName" placeholder="基站名称" />
          </el-form-item>
           <el-form-item label="告警日期">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change='changeTime'
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table stripe size="mini" border v-loading="loading" :data="tableData">
          <el-table-column min-width="60" label="省" prop="province"></el-table-column>
          <el-table-column min-width="60" label="市" prop="city"></el-table-column>
          <el-table-column min-width="60" label="县" prop="country"></el-table-column>
          <el-table-column min-width="60" label="网格" prop="region"></el-table-column>
          <el-table-column min-width="100" label="站址名称" prop="stationName"></el-table-column>
          <el-table-column min-width="70" label="站址编号" prop="stationCode"></el-table-column>
          <el-table-column min-width="120" label="停电开始时间" prop="powerFailureTime"></el-table-column>
          <el-table-column min-width="120" label="发电开始时间" prop="powerGenerationTime"></el-table-column>
          <el-table-column min-width="120" label="发电结束时间" prop="powerGenerationEndTime"></el-table-column>
          <el-table-column min-width="120" label="停电结束时间" prop="powerFailureEndTime"></el-table-column>
          <el-table-column min-width="70" label="路途时长" prop="duration"></el-table-column>
          <el-table-column min-width="70" label="停电时长" prop="powerFailureDuration"></el-table-column>
          <el-table-column min-width="70" label="发电时长" prop="powerGenerationDuration"></el-table-column>
        </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-pagination
          class="mt10"
          :current-page.sync="page"
          background
          layout="total, prev, pager, next"
          :total="total"
          @current-change="loadData"
        >
        </el-pagination>
      </custom-actions>
    </div>
  </div>
</template>

<script>
import { filterEmpyKey } from '@/utils/utils.js'
import SiderTrigger from './sider-trigger'
export default {
  name: 'PowerList',
  components: {
    SiderTrigger
  },
  data () {
    return {
      tableData: [],
      statisticsData: [],
      page: 1,
      total: 0,
      loading: false,
      loading2: false,
      dateRange: [],
      showStatistics: false,
      searchForm: {
        type: 'real',
        stationCode: null,
        stationName: null,
        stationId: null,
        startTime: null,
        endTime: null
      },
      deptList: [],
      defaultProps: {
        children: 'childNode',
        label: 'deptName',
        value: 'deptId'
      },
      msgTypeList: []
    }
  },
  computed: {
    statisticsTime () {
      return this.statisticsData.length === 0 ? '' : this.statisticsData[this.statisticsData.length - 1].ctime
    }
  },
  filters: {
    msgStateName (value) {
      switch (value) {
        case '0': return '待确认'
        case '1': return '待恢复'
        case '2': return '已恢复'
        default: return '未知'
      }
    }
  },
  watch: {
  },
  created () {
    this.loadData()
    this.loadStatisticsData()
  },
  methods: {
    siderChange (val) {
      console.log(val)
      this.showStatistics = val
    },
    changeTime (arr) {
      if (arr && arr.length > 0) {
        this.searchForm.startTime = arr[0]
        this.searchForm.endTime = arr[1]
      } else {
        this.searchForm.startTime = null
        this.searchForm.endTime = null
      }
    },
    toSetting () {
      this.$router.push('/power/setting')
    },
    getMsgType () {
      this.$axios
        .get(this.$apis.common.selectDicByList, {
          params: {
            dicType: 'msgType'
          }
        })
        .then(res => {
          this.msgTypeList = res.msgType || []
        })
    },
    msgTypeChange (value) {
      console.log('变化了')
      this.searchForm.msg = null
    },
    loadData () {
      this.loading = true
      this.$axios
        .get(this.$apis.power.queryList, {
          params: {
            page: this.page,
            ...this.searchForm
          }
        })
        .then(res => {
          this.tableData = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadStatisticsData () {
      this.loading2 = true
      this.$axios
        .get(this.$apis.power.statistics)
        .then(res => {
          this.statisticsData = res
        })
        .finally(() => {
          this.loading2 = false
        })
    },
    handleNodeClick (data) {
      console.log(data)
      this.searchForm.stationId = data.deptId
      this.loadData()
    },
    getDept () {
      this.$axios.get(this.$apis.system.selectSysDeptByList).then(res => {
        if (res) {
          this.deptList = res
        }
      })
    },
    confirm (msgId) {
      this.$confirm('是否确认该告警', '提示', {
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.device.confirmMsg, {
          msgId
        }).then(() => {
          this.loadData()
        })
      })
    },
    onSearch () {
      this.page = 1
      this.loadData()
    },
    exportFile () {
      const form = filterEmpyKey(this.searchForm)

      const token = this.$store.state?.token
      let str = ''
      for (var key in form) {
        str += key + '=' + (form[key] || '') + '&'
      }
      str = str.substr(0, str.length - 1)
      const url = `${this.$apis.power.export}?token=${token}`
      window.location.href = url + (str ? '&' + str : str)
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
  margin-top: -10px;
}

.org-item {
  display: flex;
  align-items: center;

  img {
    width: 14px;
    margin-left: 3px;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  span {
    line-height: 15px;
    font-size: 13px;
    text-decoration: underline;
  }
}
.my-table {
  margin-top: 15px;
}
.contrainer {
  display: flex;

   .contrainer-sider {
    width: 20px;
    margin-right: 15px;
  }
  .contrainer-left {
    width: 520px;
    margin-right: 15px;
    min-height: calc(100vh - 90px);
    .card {
     height: calc(100vh - 90px);
    }
  }
  .contrainer-right {
    flex: 1;
    min-width: 600px;
    min-height: calc(100vh - 90px);
  }
}

.el-tree {
  margin: -10px;
}

.el-table th {
    background-color: rgba(0, 113, 101, 0.5);
  color: #333;
  height: 32px;
}
// ::v-deep .el-tree {
//   font-size: 14px;
//   .el-tree-node__content {
//     font-size: 14px;
//     height: 34px;
//     line-height: 34px;
//   }
//   .el-tree-node__children {
//     .el-tree-node .el-tree-node__content {
//       font-size: 14px;
//       margin: 4px 0;
//       height: 34px;
//       line-height: 34px;
//       padding-right: 18px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       max-width: 350px;
//     }
//   }
// }
</style>
